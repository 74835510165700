//
// user chat.scss
//

// user chat

.user-chat {
    background-color: $card-bg;
    transition: all 0.4s;

}

.user-chat-nav {
    .nav-btn {
        height: 40px;
        width: 40px;
        line-height: 40px;
        box-shadow: none;
        padding: 0;
        font-size: 13px;;
        color: $gray-600;
    }
}


.chat-conversation {
    li {
        clear: both;
    }

    .chat-avatar {
        margin-right: 16px;

        img {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }
    }

    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 12px;

        .title {
            background-color: $border-color;
            position: relative;
            font-size: 13px;;
            z-index: 1;
            padding: 6px 12px;
            border-radius: 5px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: $border-color;
            top: 10px;
        }

        .badge {
            font-size: 13px;;
        }
    }

    .conversation-list {
        margin-bottom: 24px;
        display: inline-flex;
        position: relative;
        align-items: flex-end;

        .ctext-wrap {
            display: flex;
            margin-bottom: 10px;
        }

        .ctext-wrap-content {
            padding: 12px 20px;
            background-color: black;
            border-radius: 8px 8px 8px 0;
            color: white;
            font-size: 13px;;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                border: 5px solid transparent;
                border-left-color: black;
                border-top-color: black;
                left: 0;
                bottom: -10px;
            }
        }

        .conversation-name {
            font-weight: $font-weight-medium;
            font-size: 13px;;
        }

        .dropdown {
            .dropdown-toggle {
                font-size: 13px;;
                padding: 4px;
                color: $gray-600;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }

        }

        .chat-time {
            font-size: 13px;;
            margin-top: 4px;
            text-align: right;
            color: white;
        }

        .message-img {
            border-radius: .2rem;
            position: relative;

            .message-img-list {
                position: relative;
            }

            img {
                max-width: 150px;
            }

            .message-img-link {
                position: absolute;
                right: 10px;
                bottom: 10px;

                li {
                    >a {
                        font-size: 13px;;
                        color: $white;
                        display: inline-block;
                        line-height: 30px;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .right {

        .chat-avatar {
            order: 3;
            margin-right: 0px;
            margin-left: 16px;
        }

        .chat-time {
            text-align: left;
            color: $gray-600;
        }

        .conversation-list {
            float: right;
            text-align: right;

            .ctext-wrap {

                .ctext-wrap-content {
                    order: 2;
                    background-color: $chat-secondary-bg;
                    color: $dark;
                    text-align: right;
                    border-radius: 8px 8px 0px 8px;

                    &:before {
                        border: 5px solid transparent;
                        border-top-color: $chat-secondary-bg;
                        border-right-color: $chat-secondary-bg;
                        left: auto;
                        right: 0px;
                    }
                }
            }

            .dropdown {
                order: 1;
            }
        }

        .dot {
            background-color: $dark;
        }
    }
}

.chat-conversation {
}

.chat-input-links{
    .list-inline-item:not(:last-child){
        margin: 0;
    }
}

.animate-typing {
	.dot {
		display: inline-block;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		margin-right: -1px;
		background: $white;
        animation: wave 1.3s linear infinite;
        opacity: 0.6;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-5px);
	}
}

